import { findImageByType } from "../utils";

export default function Block({
	showLocation,
	showStreetAddress,
	showUrbanArea,
	showMunicipality,
	showRegion,
	showSlogan,
	color,
	backgroundColor,
	borderColor,
	borderRadius,
	borderThickness,
	cardsAmount,
	cardImage,
	isAdmin
}) {

	let data;
	if (goland) {
		data = goland.all_properties.filter(property => {
			return property.promoted === true;
		});
	}

	/**
	 * If the data array contains more properties than the cardsAmount, 
	 * remove properties at random until the array length is correct
	 */
	for (var i = data.length - 1; i >= cardsAmount; i--) {
		data.splice(Math.floor(Math.random() * data.length), 1);
	}

	// Render cards as div's in editor to avoid accidental clicks
	const CardTag = isAdmin ? 'div' : 'a';

	const properties = data.map(property => {
		if (property.promoted) {
			const imageObject = cardImage === 'mobilehero' ? findImageByType(property.images, cardImage, 'wide_mob') : findImageByType(property.images, cardImage, 'medium')
			const { street_address, region, urban_area, municipality } = property.main_address;

			// Create the location information array
			const location = [];
			showStreetAddress && street_address && location.push(street_address);
			showRegion && region && location.push(region);
			showUrbanArea && urban_area && location.push(urban_area);
			showMunicipality && municipality && location.push(municipality);

			return (
				<CardTag className="property-card" key={property.id} href={`${goland.pages.property.template}${property.slug}`} style={{
					backgroundColor,
					border: `solid ${borderThickness}px ${borderColor}`,
					color,
					borderRadius: `${borderRadius}px`
				}}>
					<img src={imageObject.url} alt={imageObject.alt} />
					<div className="text-container">
						<h4 className="property-name">{property.name}</h4>
						{location.length > 0 && <span className="location">{location.join(', ')}</span>}
						{showSlogan && <p className="property-slogan">{property.slogan}</p>}
					</div>
				</CardTag>
			)
		}
	})


	const gridCss = `
		@media screen and (min-width: 568px) {
			.wp-block-goland-features-goland-blocks-features-properties .cards-container {
				grid-template-columns: repeat(2, 1fr);
				column-gap: 1rem;
			}	
		}
		@media screen and (min-width: 768px) {
			.wp-block-goland-features-goland-blocks-features-properties .cards-container {
				grid-template-columns: repeat(${properties.length}, 1fr);
				column-gap: 1rem;
			}
		}
	`

	return (
		<>
			<style>{gridCss}</style>
			<div className="cards-container">
				{properties}
			</div>
		</>
	)
}